import { Link } from "@remix-run/react";
import clsx from "clsx";
import React, { AnchorHTMLAttributes, CSSProperties } from "react";

// component props interface
export interface NavLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
  to: string;
  style?: CSSProperties;
  className?: string;
}

const NavLink: React.FC<NavLinkProps> = ({ to, children, style, className, ...props }) => {
  return (
    <Link
      to={to}
      style={style}
      className={clsx(className)}
      {...props}
    >
      {children}
    </Link>
  );
};

export default NavLink;
